<template>
  <div class="row">
    <div class="col-12">
      <router-link :to="{ name: 'Home' }">Menu</router-link>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div v-if="notifications.length > 0" id="infinite-list">
        <div
          v-for="notification in notifications"
          :key="notification.timestamp"
          class="text-start border-bottom py-1"
        >
          <!-- <h5 class="mb-0">
        <strong>{{ notification.title }}</strong>
      </h5> -->
          <small>{{ formatDate(notification.timestamp) }}</small>
          <p class="mb-0 fw-bold">{{ notification.message }}</p>
          <!-- Agrega aquí más campos si los necesitas -->
        </div>

        <button
          v-if="hasMore"
          @click="fetchMoreNotifications"
          class="btn btn-light w-100"
        >
          Cargar más
        </button>
      </div>
      <div v-else class="alert alert-info text-center mt-4" role="alert">
        No tienes notificaciones
      </div>
    </div>
  </div>
  <!-- Mensaje sin notificaciones -->
</template>

<script>
import { openDB } from "idb";
import { ref, onMounted } from "vue";
import moment from "moment";

export default {
  setup() {
    const notifications = ref([]);
    const hasMore = ref(true);
    const PAGE_SIZE = 5;
    let lastKey = null;

    const fetchMoreNotifications = async () => {
      const db = await openDB("efisnet_movil", 1);
      const tx = db.transaction("notificaciones", "readonly");
      const store = tx.objectStore("notificaciones");

      const range = lastKey ? IDBKeyRange.upperBound(lastKey, true) : null;
      let cursor = await store.openCursor(range, "prev");

      let count = 0;
      while (cursor && count < PAGE_SIZE) {
        notifications.value.push(cursor.value);
        lastKey = cursor.key;
        cursor = await cursor.continue();
        count++;
      }

      if (!cursor) {
        hasMore.value = false;
      }
    };

    const formatDate = (timestamp) => {
      return moment(timestamp).format("DD/MM/YYYY h:mm:ss a");
    };

    onMounted(() => {
      // const masonry = document.querySelector("#infinite-list");
      // masonry.addEventListener("scroll", (e) => {
      //   if (masonry.scrollTop + masonry.clientHeight >= masonry.scrollHeight) {
      //     fetchMoreNotifications();
      //   }
      // });
      fetchMoreNotifications();
    });

    return {
      notifications,
      fetchMoreNotifications,
      formatDate,
      hasMore,
    };
  },
};
</script>
